import type { CartPromotionsFragment, CartTierPromotion } from '~/graphql/fragments';
import type { CartPromotions, TierGift } from './types';
import type { ExtendedCartState } from '~/composables/cart';

export const resolveCartPromotions = (
  cartPromotions: CartPromotionsFragment['cart_promotions'] | undefined | null,
  state: ExtendedCartState,
): CartPromotions => {
  return cartPromotions
    ? {
        options:
          cartPromotions.map(option => {
            return {
              is_eligible: option?.is_eligible,
              tier_minimum_subtotal: option?.tier_minimum_subtotal || 0,
              gift: mapTierGift(option as CartTierPromotion),
            };
          }) || [],
        eligiblePromotionIdx: cartPromotions.findIndex(promotion => promotion?.is_eligible) || 0,
      }
    : state.cartPromotions;
};

function mapTierGift(cartPromotionsTier: Partial<CartTierPromotion>): TierGift {
  return {
    name: cartPromotionsTier.gifts?.items?.[0]?.name as string,
    thumbnail: cartPromotionsTier.gifts?.items?.[0]?.thumbnail?.url as string,
    stock: cartPromotionsTier.gifts?.items?.[0]?.only_x_left_in_stock as number,
    sku: cartPromotionsTier.gifts?.items?.[0]?.sku as string,
  };
}
